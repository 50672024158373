import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb capell de 5 a 10 cm de diàmetre, de forma variable, acampanat, convex i obtús, i obert i pla, depenent de l’edat i freqüentment amb un mamelló central. És de color terrós a violaci, mucilaginós i lluent en temps humit. Les làmines són pàl·lides i tornen color rovell per la caiguda de les espores. Són petites, desiguals, escotades i separables. El peu és alt, robust, estriat i cobert per una substància mucilaginosa, blanquinosa, que es fragmenta amb unes esquames superposades sobre un fons groc brut. Les espores són de color rovell en massa, berrugoses, de 10-15 x 6-8 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      